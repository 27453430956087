import React, { Component } from "react";
import instanceHuay from "./axios-instanceHuay";
import instance from "./axios-instance";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import Systems from "./SystemFunction";
import md5 from 'md5';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            poyLists: [], // Store fetched Poylist data
            activeTab: "poy-today", // Default active tab
            totalAmount: 0,
            filters: 0,
            isOpen: false, // ควบคุมสถานะ dropdown เปิด/ปิด
            completedCount: 0,
            selectedText: "รายการวันนี้", // ข้อความเริ่มต้นที่แสดง
            selectedOption: "", // เก็บค่าที่เลือก
            pendingCount: 0,
            selectedFilter: "", // Selected filter for dropdown
            username: "",
            loginstate: true,
        };
        this.dropdownRef = null;
    }
    componentDidMount() {
        this.checklogin();
        document.addEventListener("click", this.handleOutsideClick);
    }
    checklogin = () => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            const decoded = jwt_decode(token);
            const Username = decoded.message.playerid;
            this.rechecklogin(Username, token);
            this.fetchPoyList(this.state.activeTab,Username);
        } else {
            this.setState({ loginstate: false });
        }
    };
    rechecklogin = async (Username, token) => {
        try {
            const response = await instance.post(
                "/api/v1/checkpoint",
                { userid: Username, System: Systems, Function: 'rechecklogin-navbar.js' },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data.status === 200) {
                const { message } = response.data;

                this.setState({
                    username: message.playerid,
                    loginstate: true,
                });
                localStorage.setItem('auth_token', response.data.token);
            } else if (response.data.status === 401) {
                localStorage.clear();
                this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            }
        } catch (error) {
            if (error.response?.status === 401 || error.response?.status === 403) {
                localStorage.clear();
                this.notify("error", 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง');
                window.location.href = '/';
            } else {
                console.error("Error during login recheck:", error);
            }
        }
    };
    // Fetch Poylist data based on the active tab and filter
    fetchPoyList = async (tab, Username) => {
        const apiUrl = `/api/v1/getbuylistfilterbysystem?type=${tab}&filter=${this.state.filters}&Playerid=${Username}&system=${Systems}`;
        const headers = { "Content-Type": "application/json", key: "param3" };

        try {
            const response = await instanceHuay.get(apiUrl, { headers });
            if (response.status === 200) {
                const poyLists = response.data;
                // คำนวณยอดรวม
                const totalAmount = poyLists.reduce((sum, poy) => sum + poy.sumtotal, 0);
                // คำนวณจำนวนโพย "ออกผลแล้ว"
                const completedCount = poyLists.filter((poy) => poy.rewardStatus === "ถูกรางวัล" || poy.rewardStatus === "ไม่ถูกรางวัล").length;

                // คำนวณจำนวนโพย "ยังไม่ออกผล"
                const pendingCount = poyLists.filter((poy) => poy.rewardStatus === "รอออกผล").length;

                // อัปเดต state
                this.setState({
                    poyLists,
                    totalAmount,
                    completedCount,
                    pendingCount,
                });
            }
        } catch (error) {
            console.error('Error fetching buylist:', error);
        }
    };


    // Handle tab change
    handleTabChange = (tab) => {
        this.setState({ activeTab: tab }, () => {
            this.fetchPoyList(tab, this.state.username);
        });
    };

    // Handle dropdown filter change
    handleFilterChange = (event) => {
        const filter = event.target.value;
        this.setState({ selectedFilter: filter }, () => {
            this.fetchPoyList(this.state.activeTab,this.state.username);
        });
    };


    toggleDropdown = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };
    handleOutsideClick = (event) => {
        if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    };
    handleSelection = (text, index) => {
        this.setState({ selectedText: text, isOpen: false, filters: index });
    };


    componentWillUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
    }

    render() {
        const { poyLists, activeTab, selectedFilter } = this.state;
        const { isOpen, selectedText } = this.state;
        return (
            <>
                <div id="section-content" className="container">
                    <div className="bar-back">
                        <a href={`/${window.location.search}`}>
                            <i className="fas fa-chevron-left" /> หน้าหลัก
                        </a>
                    </div>
                    <div
                        className="p-2 w-100 bg-light main-content align-self-stretch"
                        style={{ minHeight: "calc(-190px + 100vh)" }}
                    >
                        <div className="border border-dark bg-white shadow-sm rounded mb-2">
                            <div className="bg-dark text-white py-1 px-2">
                                <i className="sn-icon sn-icon--horoscope2" /> ยอดแทงวันนี้{" "}
                            </div>
                            <div className="bg-light py-1 px-2">
                                <div className="row px-3">
                                    <div className="col-6 col-sm-6 pl-0 pr-1">
                                        <div className="mr-1 mt-1 py-1 text-center w-100 rounded bg-primary text-white">
                                            <small>ยอดรวม</small>
                                            <h5 className="font-weight-normal thb">฿ {this.state.totalAmount || 0}</h5>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 px-0">
                                        <div className="m-1 py-1 text-center border-left w-100">
                                            <small>ออกผลแล้ว</small>
                                            <h5 className="font-weight-light text-success">{this.state.completedCount || 0}</h5>
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 px-0">
                                        <div className="m-1 py-1 text-center border-left w-100">
                                            <small>ยังไม่ออกผล</small>
                                            <h5 className="font-weight-light text-primary">{this.state.pendingCount || 0}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border border-secondary bg-white shadow-sm rounded mb-5">
                            <div className="row m-0 poy-all">
                                {/* Dropdown for Filter */}
                                <div className="col-12 col-sm-12 col-md-4 p-0 bg-dark">
                                    <div
                                        className={`dropdown bootstrap-select form-control ${isOpen ? "show dropup" : "dropup"}`}
                                        ref={(ref) => (this.dropdownRef = ref)}
                                    >
                                        <button
                                            className="btn dropdown-toggle bs-placeholder btn-dark"
                                            onClick={this.toggleDropdown}
                                        >
                                            <div className="filter-option">
                                                <div className="filter-option-inner">{selectedText}</div>
                                            </div>
                                            &nbsp;<span className="bs-caret">
                                                <span className="caret"></span>
                                            </span>
                                        </button>
                                        <div
                                            className={`dropdown-menu ${isOpen ? "show" : ""}`}
                                            style={{
                                                maxHeight: "233.5px",
                                                overflow: "hidden",
                                                minHeight: 116,
                                                position: "absolute",
                                                willChange: "transform",
                                                top: 0,
                                                left: 0,
                                                transform: "translate3d(0px, 38px, 0px)",
                                            }}
                                            x-placement="bottom-start"
                                        >
                                            <ul className={`inner ${isOpen ? "show" : ""}`}>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => this.handleSelection("หวยทั้งหมด", 1)}
                                                    >
                                                        รายการวันนี้
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onClick={() => this.handleSelection("ย้อนหลังทั้งหมด", 2)}
                                                    >
                                                        ทั้งหมด
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* Tab Menu */}
                                <div className="col-3 col-sm-3 col-md-2 p-0">
                                    <button
                                        onClick={() => this.handleTabChange("poy-today")}
                                        className={`btn btn-primary btn-sm btn-block h-100 btn-poy ${activeTab === "poy-today" ? "active" : ""
                                            }`}
                                    >
                                        <i className="fas fa-calendar-week d-none" />
                                        <div>
                                            <span>โพย</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 p-0">
                                    <button
                                        onClick={() => this.handleTabChange("poy-notyet")}
                                        className={`btn btn-warning btn-sm btn-block h-100 btn-poy ${activeTab === "poy-notyet" ? "active" : ""
                                            }`}
                                    >
                                        <i className="fas fa-times-circle d-none" />
                                        <div>
                                            <span>โพยหวย</span>
                                            <span>ที่ยังไม่ออกผล</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 p-0">
                                    <button
                                        onClick={() => this.handleTabChange("poy-success")}
                                        className={`btn btn-success btn-sm btn-block h-100 btn-poy ${activeTab === "poy-success" ? "active" : ""
                                            }`}
                                    >
                                        <i className="fas fa-check-circle d-none" />
                                        <div>
                                            <span>โพยหวย</span>
                                            <span>ออกผลแล้ว</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="col-3 col-sm-3 col-md-2 p-0">
                                    <button
                                        onClick={() => this.handleTabChange("poy-history")}
                                        className={`btn btn-secondary btn-sm btn-block h-100 btn-poy ${activeTab === "poy-history" ? "active" : ""
                                            }`}
                                    >
                                        <i className="fas fa-history d-none" />
                                        <div>
                                            <span>โพย</span>
                                            <span>ก่อนหน้า</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {/* Content for "poy-today" */}
                            <div
                                className={`col-12 p-0 table-primary poy-content ${activeTab === "poy-today" ? "active" : "d-none"
                                    }`}
                            >
                                {/* Render poyLists for "poy-today" */}
                                {poyLists.length > 0 ? (
                                    poyLists.map((poy, index) => (
                                        <div className="poy-list">
                                            <div className="poy-list-head">
                                                <small>โพยเลขที่</small>
                                                <span> #{poy.track}</span>
                                                <div
                                                    className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                        poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                        }`}
                                                >
                                                    {poy.rewardStatus}
                                                </div>
                                            </div>
                                            <div className="poy-list-content">
                                                <div className="row">
                                                    <div className="col m-0 pl-2 pr-1 pb-1">
                                                        <div className="poy-type">
                                                            <span>{poy.roomDetails.name}</span>
                                                            <br />
                                                            <span>{poy.roomDetails.type}</span>
                                                            <br />
                                                            <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                        <div className="d-flex justify-content-between">
                                                            <small>เงินเดิมพัน</small>
                                                            <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <small>ผลแพ้/ชนะ</small>
                                                            <span>
                                                                <span className="thb">฿ {poy.winAmount || 0}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 border-top m-0 pt-1">
                                                        <span className="badge">
                                                            <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <span className="badge">
                                                            <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: false,
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <a
                                                            href={`/poydetail?track=${poy.track}`}
                                                            className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                        >
                                                            รายละเอียด <i className="fas fa-search" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center">
                                        <span className="text-danger">ไม่มีรายการ</span>
                                    </div>
                                )}
                            </div>

                            {/* Content for "poy-notyet" */}
                            <div
                                className={`col-12 p-0 table-danger poy-content ${activeTab === "poy-notyet" ? "active" : "d-none"
                                    }`}
                            >
                                {poyLists.length > 0 ? (
                                    poyLists.map((poy, index) => (
                                        <div className="poy-list">
                                            <div className="poy-list-head">
                                                <small>โพยเลขที่</small>
                                                <span> #{poy.track}</span>
                                                <div
                                                    className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                        poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                        }`}
                                                >
                                                    {poy.rewardStatus}
                                                </div>
                                            </div>
                                            <div className="poy-list-content">
                                                <div className="row">
                                                    <div className="col m-0 pl-2 pr-1 pb-1">
                                                        <div className="poy-type">
                                                            <span>{poy.roomDetails.name}</span>
                                                            <br />
                                                            <span>{poy.roomDetails.type}</span>
                                                            <br />
                                                            <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                        <div className="d-flex justify-content-between">
                                                            <small>เงินเดิมพัน</small>
                                                            <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <small>ผลแพ้/ชนะ</small>
                                                            <span>
                                                                <span className="thb">฿ {poy.winAmount || 0}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 border-top m-0 pt-1">
                                                        <span className="badge">
                                                            <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <span className="badge">
                                                            <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: false,
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <a
                                                            href={`/poydetail?track=${poy.track}`}
                                                            className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                        >
                                                            รายละเอียด <i className="fas fa-search" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center">
                                        <span className="text-danger">ไม่มีรายการ</span>
                                    </div>
                                )}
                            </div>

                            {/* Content for "poy-success" */}
                            <div
                                className={`col-12 p-0 table-success poy-content ${activeTab === "poy-success" ? "active" : "d-none"
                                    }`}
                            >
                                {poyLists.length > 0 ? (
                                    poyLists.map((poy, index) => (
                                        <div className="poy-list">
                                            <div className="poy-list-head">
                                                <small>โพยเลขที่</small>
                                                <span> #{poy.track}</span>
                                                <div
                                                    className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                        poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                        }`}
                                                >
                                                    {poy.rewardStatus}
                                                </div>
                                            </div>
                                            <div className="poy-list-content">
                                                <div className="row">
                                                    <div className="col m-0 pl-2 pr-1 pb-1">
                                                        <div className="poy-type">
                                                            <span>{poy.roomDetails.name}</span>
                                                            <br />
                                                            <span>{poy.roomDetails.type}</span>
                                                            <br />
                                                            <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                        <div className="d-flex justify-content-between">
                                                            <small>เงินเดิมพัน</small>
                                                            <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <small>ผลแพ้/ชนะ</small>
                                                            <span>
                                                                <span className="thb">฿ {poy.winAmount || 0}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 border-top m-0 pt-1">
                                                        <span className="badge">
                                                            <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <span className="badge">
                                                            <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: false,
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <a
                                                            href={`/poydetail?track=${poy.track}`}
                                                            className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                        >
                                                            รายละเอียด <i className="fas fa-search" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center">
                                        <span className="text-danger">ไม่มีรายการ</span>
                                    </div>
                                )}
                            </div>

                            {/* Content for "poy-history" */}
                            <div className={`col-12 p-0 table-secondary poy-content ${activeTab === "poy-history" ? "active" : "d-none"}`} >
                                {poyLists.length > 0 ? (
                                    poyLists.map((poy, index) => (
                                        <div className="poy-list">
                                            <div className="poy-list-head">
                                                <small>โพยเลขที่</small>
                                                <span> #{poy.track}</span>
                                                <div
                                                    className={`poy-status ${poy.rewardStatus === "ถูกรางวัล" ? "win" :
                                                        poy.rewardStatus === "ไม่ถูกรางวัล" ? "lost" : "notyet"
                                                        }`}
                                                >
                                                    {poy.rewardStatus}
                                                </div>
                                            </div>
                                            <div className="poy-list-content">
                                                <div className="row">
                                                    <div className="col m-0 pl-2 pr-1 pb-1">
                                                        <div className="poy-type">
                                                            <span>{poy.roomDetails.name}</span>
                                                            <br />
                                                            <span>{poy.roomDetails.type}</span>
                                                            <br />
                                                            <small>วันที่ :  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.roomDetails.drawTime))}</small>
                                                        </div>
                                                    </div>
                                                    <div className="col m-0 pl-1 pr-3 pb-1 border-left">
                                                        <div className="d-flex justify-content-between">
                                                            <small>เงินเดิมพัน</small>
                                                            <span className="thb text-info">฿ {poy.sumtotal}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <small>ผลแพ้/ชนะ</small>
                                                            <span>
                                                                <span className="thb">฿ {poy.winAmount || 0}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 border-top m-0 pt-1">
                                                        <span className="badge">
                                                            <i className="far fa-calendar-alt" />  {new Intl.DateTimeFormat("th-TH", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <span className="badge">
                                                            <i className="far fa-clock" />  {new Intl.DateTimeFormat("th-TH", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                                hour12: false,
                                                            }).format(new Date(poy.date))}
                                                        </span>
                                                        <a
                                                            href={`/poydetail?track=${poy.track}`}
                                                            className="btn btn-secondary btn-sm py-0 px-1 float-right"
                                                        >
                                                            รายละเอียด <i className="fas fa-search" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-center">
                                        <span className="text-danger">ไม่มีรายการ</span>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default App;
