import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import './style.css';
import Main from './main';
import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Arcade from './arcade';
import Event from './event';
import Sport from './sport';
import Fising from './fising';
import Signin from './signin';
import Profile from './profile';
import Promotion from './promotionx';
import Login from './login';
import Logout from './logout';
import Statement from './statement';
import Deposit from './deposit';
import Withdraw from './withdraw';
import Affiliate from './affiliate';
import Banner from './banner';
import Loss from './loss';
import Linklistall from './linklistall';
import Linklistalldemo from './linklistaldemo';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import Freegame from './slotfree';
import Register from './register';
import io from "socket.io-client";
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import Spinwheels from './spinweel';
import $ from "jquery";
import Paoyingchub from './game/paoyingchub';
import { Flag } from '@mui/icons-material';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
    
    }
  }


  render() {

    return (
      < >



          <BrowserRouter >
            <Routes>
              <Route path="/*" element={<Main />} />
              <Route path="/logout" element={<Logout />} /> 
            </Routes>
          </BrowserRouter>      

        <ToastContainer />
      </>
    );
  }
}

export default App;
